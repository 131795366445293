import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import CustomButton from "../../../shared_components/CustomButton";

const programData = [
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1732091853/o4fndsikuwxsnwohmviw.png`,
    title: "Community Building",
    description: "Engage in exclusive member events and activities.",
    link: "/community",
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1732094289/thkp5smparng94uzo5zf.png`,
    title: "Internship Opportunities",
    description: "Gain real-world experience with top companies.",
    link: "/internship",
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1732094788/set3hisw114f1vlrvrue.png`,
    title: "Hackathons & Competitions",
    description: "Show off your skills and win exciting prizes.",
    link: "/wittyhacks",
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1732179824/fecmr8bgtxzjylkhkb8s.png`,
    title: "30 Days of Code",
    description: "Stay ahead with practical learning sessions.",
    link: "/dayscode",
  },
  {
    img: `http://res.cloudinary.com/datacode/image/upload/v1732095227/y5tkfeuejqpgweeuujsl.png`,
    title: "Events ",
    description: "Expand your expertise through interactive sessions.",
    link: "/aisummit",
  },
];

const ProgramThatMakeDiff = () => {
  return (
    <div className="row mx-0 my-5 justify-content-center programThatMakeDiff">
      <div className="col-11 col-md-10 col-lg-10 ">
        <div className="row mx-0">
          <div className="col-12 col-md-4 col-lg-4  p-3 container">
            <div className=" pl-0 pr-md-4">
              <h2 className="pr-md-5  text-center text-md-left">
                Programs That Make a Difference
              </h2>
              <p
                className="py-3 pr-md-5 text-center text-md-left"
                style={{ fontSize: "17px" }}
              >
                We believe in transforming potential into performance
              </p>
            </div>
          </div>
          {programData.map((items) => (
            <div className="col-12 col-md-4 col-lg-4 p-3 container">
              <div class="card">
                <img
                  src={items.img}
                  class="card-img-top border-bottom"
                  alt="..."
                  height={180}
                />
                <div class="card-body">
                  <h5 class="card-title">{items.title}</h5>
                  <p class="card-text py-2">{items.description}</p>
                  {/* <Link to={items.link} class="btn btn-primary py-2 px-3">
                    Learn More
                  </Link> */}
                  <Link to={items.link} className="py-0 px-0">
                    <CustomButton buttonName="Learn More" className="px-0 py-0"   buttonStyle={{ padding:"7px 15px 7px 15px" , fontSize: "14px" }}  />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ProgramThatMakeDiff;
