import React, { useEffect } from "react";
import AOS from "aos";

const techData = [
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731593868/dwjtv6phpjkejuaipauf.png`,
    name: "React Js",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731596111/k3ng6yv7chhnwmvhpmkn.png`,
    name: "Next Js",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731594065/ezdwt87nlyxxp3ef08h1.png`,
    name: "Java Script",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731594118/wyp3mdvkgbjnws3kykcu.png`,
    name: "Html",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731594008/nkt2bsow7fcqzdjeln8y.png`,
    name: "CSS",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731594186/ui6ih9xgnrbbjwwacpt8.png`,
    name: "Bootstrap",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731594232/yv7wfm9hjijoibvy6gz0.jpg`,
    name: "Mongo DB",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731594286/pow4ccqa7hgqt4fboxwy.png`,
    name: "Type Script",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731594332/oxguqhjhu4sfoghmkjdd.png`,
    name: "Express",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731594387/ez2xalkohaihxhobrvyd.png`,
    name: "Node Js",
  },
  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731594440/gc9pybgacdmdajrxmeh7.png`,
    name: "Tailwind",
  },

  {
    image1: `http://res.cloudinary.com/datacode/image/upload/v1731594476/fi7rylpw75rjyddaaffc.png`,
    name: "Sass",
  },
];

const Technologies = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="row mx-0 my-5 justify-content-center technology">
      <div className="col-11 col-md-10 col-lg-10 px-0 ">
        <h1 className="text-center pt-lg-5 pt-4 heading">
          <span>Technologies</span> On Which We Work
        </h1>
        <div className="row mx-0 pt-5">
          {techData.map((items) => (
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 justify-content-center pb-4">
              <div
                className="p-3 shadow-sm justify-content-center d-flex"
                data-aos="slide-left"
                data-aos-delay="100"
              >
                <img src={items.image1} className="" height="40vh" />
                <h4 className="pl-2 d-flex align-items-center mb-0">
                  {items.name}
                </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Technologies;
