import React, { useEffect } from "react";
import { FcLike, FcBullish } from "react-icons/fc";
import AOS from "aos";

const Events = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="row mx-0 justify-content-center events mt-5 py-lg-5 py-4">
      <div className="col-11 col-md-10 col-lg-10 px-lg-0 px-2">
        <div className="row mx-0">
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center"
            data-aos="fade-up"
          >
            <div className="">
              <h1 className="head">
                Meet Up's With <span>Intern</span> <FcLike />
              </h1>
              <p className="py-3 head para text-justify text-md-left text-lg-left">
                In a world of bits and bytes, we put our people in the
                forefront. At Datacode, you feel you belong, working with
                like-minded people, seeking to shape the future together.
              </p>
              <p className="pb-3 head para text-justify text-md-left text-lg-left">
                Our culture is unique, even a little special: we are committed
                to the end goal - but also, to each other and the community.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="" data-aos="fade-up">
              <img
                src={`http://res.cloudinary.com/datacode/image/upload/v1731594935/xppjlevyqyvg9vtwqm06.jpg`}
                className="img-fluid peopleimg"
              />
            </div>
          </div>
        </div>
        {/* ----------------------------------------------------------------------------------- */}
        <div className="row mx-0 mt-lg-5 pt-5">
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 order-2 order-sm-2 order-md-1 order-lg-1"
            data-aos="fade-up"
          >
            <div className="">
              <img
                src={`http://res.cloudinary.com/datacode/image/upload/v1731929666/cmawnrs3jl2hv7scj4ob.jpg`}
                className="img-fluid peopleimg"
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 order-1 order-sm-1 order-md-2 order-lg-2 d-flex align-items-center justify-content-center">
            <div data-aos="fade-up">
              <h1 className="head">
                Grow Your <span>Career</span>{" "}
                <span>
                  <FcBullish />
                </span>
              </h1>
              <p className="py-3 head para text-justify text-md-left text-lg-left">
                Constantly reinvent yourself as you follow your passions,
                charting your own unique career path.
              </p>
              <p className="pb-3 head para text-justify text-md-left text-lg-left">
                As a skills-driven company, we're committed to creating the
                environment in which you can thrive, explore new options and
                advance new frontiers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Events;
