import React from 'react'
import "../assets/scss/customComponent.scss";
import PropTypes from 'prop-types';


const CustomButton = ({iconClass , buttonName , buttonSize ,buttonStyle , onClick }) => {
  return (
    <>
      <button  className={`btn btn-custom-button ${buttonSize}`} style={buttonStyle} onClick={onClick}>
      <span>{buttonName}</span>
      </button>
    </>
  )
}

export default CustomButton

CustomButton.defaultProps = {
  iconClass: '',
}

CustomButton.propTypes = {
  buttonName: PropTypes.string,
  buttonStyle: PropTypes.object,
  onClick : PropTypes.func,
  
};

