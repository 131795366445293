import { Checkbox, Radio } from "antd";
import Parser from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaBookmark,
  FaComment,
  FaEllipsisH,
  FaEye,
  FaHeart,
  FaShareAlt,
} from "react-icons/fa";
import { LuClipboardType } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs";
import CustomLoader from "../../../shared_components/CustomLoader";
import ToolTip from "../../../shared_components/Tooltip";

import {
  clearLessonDetails,
  getAllLessons,
  getLessonDetails,
} from "../DaysCode/actions";
import CodePenBlockPreview from "./CodePenBlockPreview";
import GistBlockPreview from "./GistBlockPreview";
import YoutubeBlockPreview from "./YoutubeBlockPreview";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { IoBulbOutline } from "react-icons/io5";
import { BsInfoCircle } from "react-icons/bs";
import { AiFillNotification } from "react-icons/ai";
import { FaNoteSticky } from "react-icons/fa6";
import { HiMiniMagnifyingGlassCircle } from "react-icons/hi2";
import { IoIosWarning } from "react-icons/io";

const BlogPreview = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { lessonDetails, lessonsList, problemsListLoading } = useSelector(
    (state) => state.dayscode
  );
  const [isLiked, setIsLiked] = useState(false);
  const [quizSingleOption, setQuizSingleOption] = useState("");
  const [quizMultiOption, setQuizMultiOption] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllLessons());
      await dispatch(getLessonDetails(id));
      setIsLoading(false);
    };

    fetchData();
    return () => {
      dispatch(clearLessonDetails());
    };
  }, []);

  const blog = {
    tags: ["React", "JavaScript", "Web Development"],
  };

  if (isLoading || problemsListLoading || !lessonDetails) {
    return <CustomLoader />;
  }

  return (
    <>
      <div className="row mx-0 d-flex justify-content-center">
        <div className="col-10">
          <div className="d-none m-3 d-md-block">
            <div
              className="d-flex align-items-center text-decoration-none "
              onClick={() => history.push("/blogs")}
            >
              <p className="mr-2 cursor-pointer"><FaArrowLeft /></p> <p className="cursor-pointer">Back to Blogs</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0 mt-5 d-flex justify-content-center">
        <div className="col-12 px-lg-0 px-2 ">
          <div className="d-flex row mx-0 ">
            <div className=" pb-5 d-flex flex-column col-12 blogs blog-w ">
              <article className="blog-preview mt-3 ">
                <div className="row mx-0 justify-content-center ">
                  <div className="col-md-8 blog-preview-title">
                    <h1 className="mb-3 text-center blog-heading-text">
                      {lessonDetails?.name}
                    </h1>
                    {lessonDetails?.description
                      ?.split("\n\n")
                      .map((paragraph, index) => (
                        <p className="text-center py-3" key={index} >
                          {paragraph}
                        </p>
                      ))}
                    <div className="text-center Date-section mt-5">
                      <p className="mb-0">
                        {moment(lessonDetails?.createdAt).format(
                          "MMMM Do YYYY"
                        )}
                      </p>
                    </div>
                    <div className="d-flex py-2 align-items-center justify-content-center">
                      <div className="rounded-pill d-flex justify-content-center align-items-center Author-Name">
                        <img
                          className="img-section rounded-circle border"
                          height={35}
                          width={35}
                          src={
                            lessonDetails.author?.imgUrl ||
                            "https://miro.medium.com/v2/resize:fill:20:20/1*abnkL8PKTea5iO2Cm5H-Zg.png"
                          }
                          alt=""
                        />
                        <p className="author-name pl-2 mb-0">
                          {lessonDetails.author
                            ? lessonDetails.author?.firstName +
                            " " +
                            lessonDetails.author?.lastName
                            : "Datacode Author"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 pt-5">
                    <div className="row mx-0 ">
                      <div className="col-md-3 col-12 d-md-block d-none"></div>
                      <div className="col-12 px-0 col-md-6">
                        {/* blog last update section */}
                        <div className="blog-title-footer justify-content-between d-flex py-3">
                          <p className="text-muted align-items-center">
                            Last Updated on{" "}
                            {moment(lessonDetails?.updatedAt).format(
                              "MMMM Do YYYY"
                            )}{" "}
                            ● 10 minute read
                          </p>
                          <div className="">
                            <span className="share">
                              <span>
                                <FaFacebookSquare />
                              </span>
                              <span>
                                <FaSquareXTwitter />
                              </span>
                              <span>
                                <FaLinkedin />
                              </span>
                            </span>
                          </div>
                        </div>
                        <img
                          src={lessonDetails.thumbnail}
                          className="img-fluid mb-3 animate__animated animate__fadeIn w-100 "
                          alt={lessonDetails.name}
                        />
                        {/* tutorial details div */}
                        <div className="mb-4">
                          <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                            <span className="badge bg-primary px-2 py-2 text-white blog-preview-badge">
                              <b>{lessonDetails.category}</b>
                            </span>
                          </div>

                          <div className="d-flex flex-wrap gap-2 mb-4">
                            {blog.tags.map((tag) => (
                              <span
                                key={tag}
                                className="badge bg-light text-dark"
                              >
                                {tag}
                              </span>
                            ))}
                          </div>
                        </div>
                        {/* changes code is here */}
                        <div>
                          {lessonDetails?.contents?.map((content) => {
                            return (
                              <div className="textEditor">
                                {content.type === "textEditor" && (
                                  <div>
                                    <p>{Parser(String(content.payload))}</p>
                                  </div>
                                )}
                                {content.type === "quizSingleOption" && (
                                  <div className="d-flex flex-column border shadow-sm rounded-lg my-5">
                                    <label className="border-bottom px-3 py-2">
                                      <div className="quiz-question-text">{content.payload.question}?</div>
                                    </label>
                                    <Radio.Group
                                      className="d-flex flex-column px-3"
                                      // value={quizSingleOption}
                                      onChange={(e) => {
                                        e.target.value ===
                                          content.payload.option1 &&
                                          setQuizSingleOption("option1");
                                        e.target.value ===
                                          content.payload.option2 &&
                                          setQuizSingleOption("option2");
                                        e.target.value ===
                                          content.payload.option3 &&
                                          setQuizSingleOption("option3");
                                        e.target.value ===
                                          content.payload.option4 &&
                                          setQuizSingleOption("option4");
                                      }}
                                    >
                                      <Radio
                                        value={content.payload.option1}
                                        className="border rounded-lg my-2 px-2 py-3"
                                      >
                                        {content.payload.option1}
                                      </Radio>
                                      <Radio
                                        value={content.payload.option2}
                                        className="border rounded-lg my-2 px-2 py-3"
                                      >
                                        {content.payload.option2}
                                      </Radio>
                                      <Radio
                                        value={content.payload.option3}
                                        className="border rounded-lg my-2 px-2 py-3"
                                      >
                                        {content.payload.option3}
                                      </Radio>
                                      <Radio
                                        value={content.payload.option4}
                                        className="border rounded-lg my-2 px-2 py-3"
                                      >
                                        {content.payload.option4}
                                      </Radio>
                                    </Radio.Group>
                                    <div className="blog-content-btn pb-3">
                                      <button
                                        className="btn bg-violet text-light float-right "
                                        onClick={() => {
                                          console.log(
                                            content.payload.correct_answer ===
                                            quizSingleOption && "correct"
                                          );
                                        }}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                )}
                                {content.type === "quizMultiOption" && (
                                  <div className="d-flex flex-column border shadow-sm rounded-lg my-5">
                                    <label className="border-bottom px-3 py-2">
                                      <div className="quiz-question-text">{content.payload.question}?</div>
                                    </label>
                                    <Checkbox.Group className="d-flex flex-column px-3">
                                      <Checkbox
                                        value={content.payload.text1}
                                        className="border rounded-lg my-2 px-2 py-3"
                                        onChange={(e) => {
                                          setQuizMultiOption(e.target.value);
                                        }}
                                      >
                                        {content.payload.text1}
                                      </Checkbox>
                                      <Checkbox
                                        value={content.payload.text2}
                                        className="border rounded-lg my-2 px-2 py-3"
                                        onChange={(e) => {
                                          setQuizMultiOption(e.target.value);
                                        }}
                                      >
                                        {content.payload.text2}
                                      </Checkbox>
                                      <Checkbox
                                        value={content.payload.text3}
                                        className="border rounded-lg my-2 px-2 py-3"
                                        onChange={(e) => {
                                          setQuizMultiOption(e.target.value);
                                        }}
                                      >
                                        {content.payload.text3}
                                      </Checkbox>
                                      <Checkbox
                                        value={content.payload.text4}
                                        className="border rounded-lg my-2 px-2 py-3"
                                        onChange={(e) => {
                                          setQuizMultiOption(e.target.value);
                                        }}
                                      >
                                        {content.payload.text4}
                                      </Checkbox>
                                    </Checkbox.Group>
                                    <div className="blog-content-btn pb-3">
                                      <button className="btn bg-violet text-light float-right ">
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                )}
                                {content.type === "textEditorElements" && (
                                  <div className="my-5 textEditorElements">
                                    {content.subType === "tip" && (
                                      <div className="tip rounded-lg">
                                        <div className="head px-3 d-flex align-items-center">
                                          <span>
                                            <IoBulbOutline
                                              size={25}
                                              className="mr-1"
                                            />
                                          </span>
                                          <span>{content.subType}</span>
                                        </div>
                                        <div className="p-3 content">
                                          {Parser(String(content?.payload))}
                                        </div>
                                      </div>
                                    )}
                                    {content.subType === "info" && (
                                      <div className="info rounded-lg">
                                        <div className="head px-3">
                                          <BsInfoCircle className="mr-1" />
                                          {content.subType}
                                        </div>
                                        <div className="p-3 content">
                                          {Parser(String(content?.payload))}
                                        </div>
                                      </div>
                                    )}
                                    {content.subType === "important" && (
                                      <div className="important rounded-lg">
                                        <div className="head px-3">
                                          <AiFillNotification className="mr-1" />
                                          {content.subType}
                                        </div>
                                        <div className="p-3 content">
                                          {Parser(String(content?.payload))}
                                        </div>
                                      </div>
                                    )}
                                    {content.subType === "note" && (
                                      <div className="note rounded-lg">
                                        <div className="head px-3">
                                          <FaNoteSticky className="mr-1" />
                                          {content.subType}
                                        </div>
                                        <div className="p-3 content">
                                          {Parser(String(content?.payload))}
                                        </div>
                                      </div>
                                    )}
                                    {content.subType === "fact" && (
                                      <div className="fact rounded-lg">
                                        <div className="head px-3">
                                          <HiMiniMagnifyingGlassCircle
                                            className="mr-1"
                                            size={25}
                                          />
                                          {content.subType}
                                        </div>
                                        <div className="p-3 content">
                                          {Parser(String(content?.payload))}
                                        </div>
                                      </div>
                                    )}
                                    {content.subType === "warning" && (
                                      <div className="warning rounded-lg">
                                        <div className="head px-3">
                                          <IoIosWarning
                                            className="mr-1"
                                            size={25}
                                          />
                                          {content.subType}
                                        </div>
                                        <div className="p-3 content">
                                          {Parser(String(content?.payload))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                                {content.type === "codeBlock" && (
                                  <div className="my-5">
                                    <div className="border-top border-left border-right rounded">
                                      <div className="d-flex justify-content-between">
                                        <h5 className="pt-2 px-2">
                                          Code Block
                                        </h5>
                                        <ToolTip
                                          id={"copytoClip"}
                                          message={"Copy to clipboard"}
                                          placement={"right"}
                                        />
                                        <h5
                                          className="pt-2 px-2 copytoClip"
                                          id="copytoClip"
                                        >
                                          <LuClipboardType />
                                        </h5>
                                      </div>
                                      <SyntaxHighlighter
                                        language={content?.payload?.language}
                                        style={monokai}
                                      >
                                        {content?.payload?.code}
                                      </SyntaxHighlighter>
                                    </div>{" "}
                                  </div>
                                )}{" "}
                                {content.type === "imageBlock" && (
                                  <div className="my-5 ">
                                    <h4 className="text-center mb-2 blog-headings">
                                      Image Resources
                                    </h4>
                                    <img
                                      src={content?.payload}
                                      alt={"img-block"}
                                      height={"100%"}
                                      width={"100%"}
                                    />
                                  </div>
                                )}{" "}
                                {content.type === "codePenBlock" && (
                                  <CodePenBlockPreview item={content.payload} />
                                )}{" "}
                                {content.type === "youtubeBlock" && (
                                  <YoutubeBlockPreview item={content.payload} />
                                )}
                                {content.type === "githubGistBlock" && (
                                  <div className="my-4">
                                    <GistBlockPreview item={content.payload} />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-md-3 col-12 d-none d-md-block justify-content-end side-section py-5 px-5 mt-4 mb-0 ">
                        <div className="side-section-div p-3 shadow-sm">
                          <h4>What is Datacode?</h4>
                          <p className="mb-0">
                            Datacode Provides you the Best free Learning Content
                            from all over the world to Improve your Skills and
                            Make your Learning Easy and Quick. Either one is at
                            Beginners or at the Advance level you find your
                            track and learn from it.
                          </p>
                          <Link to="/">Learn more about us</Link>
                        </div>
                      </div>
                    </div>
                    <div className="row mx-0 justify-content-center">
                      <div className="col-11">
                        <div className=" featured-blog-preview mx-0 ">
                          <h4 className="blog-headings text-center py-4">
                            Featured Blogs
                          </h4>
                          <div className="row justify-content-around mx-0">
                            {lessonsList &&
                              lessonsList.slice(0, 4).map((lesson) => {
                                return (
                                  <div
                                    className="col-12 col-md-4 px-0 rounded my-3 mx-auto"
                                    key={lesson._id}
                                  >
                                    <div className="border mx-2 blog-top">
                                      <div className="blog-img ">
                                        <img
                                          src={
                                            lesson.thumbnail ||
                                            "https://c7.alamy.com/comp/D6BK05/shadow-looming-over-frightened-boy-D6BK05.jpg"
                                          }
                                          alt={lesson?.name}
                                        />
                                      </div>
                                      <div className="d-flex justify-content-between align-items-center px-1 mt-2 blog-head">
                                        <span className="">
                                          <img
                                            src={
                                              lesson.author?.imgUrl ||
                                              "https://c7.alamy.com/comp/D6BK05/shadow-looming-over-frightened-boy-D6BK05.jpg"
                                            }
                                            alt="Author name"
                                            height={30}
                                            width={30}
                                            className=" rounded-pill border"
                                          />
                                          <span className="ml-1">
                                            {lesson.author?.firstName +
                                              lesson.author?.lastName ||
                                              "Deep Giando"}
                                          </span>
                                        </span>
                                        <span>
                                          <span className="mr-2 d-felx align-items-center">
                                            <span>
                                              <FaEye />
                                            </span>
                                            <span> 1200</span>
                                          </span>
                                          <span className="ml-2">
                                            5 min Read
                                          </span>
                                        </span>
                                      </div>
                                      <div className="blog-content px-2 mt-2">
                                        <h4>
                                          {lesson?.name ||
                                            "None is there but WHO??"}
                                        </h4>
                                        <p>
                                          {lesson.description
                                            ? lesson.description?.slice(0, 75) +
                                            "..."
                                            : " adipisicingelit. Voluptatum suscipit labore ad perferendis odi odi odi odi..."}
                                        </p>
                                      </div>
                                      <div className="blog-footer px-2 mb-2">
                                        <span>
                                          <span
                                            role="img"
                                            aria-label="icon"
                                            className="mx-2"
                                          >
                                            ✨
                                          </span>
                                          <span className="">
                                            {moment(
                                              lessonDetails?.updatedAt
                                            ).format("MMM D")}
                                          </span>
                                          <span>
                                            <FaHeart className="mx-2" />
                                            450
                                          </span>
                                          <span>
                                            <FaComment className="mx-2" />
                                            27
                                          </span>
                                        </span>
                                        <span>
                                          <span>
                                            <FaBookmark className="mx-2" />
                                          </span>
                                          <span>
                                            <FaEllipsisH className="mx-2" />
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPreview;
