import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import { ReactComponent as Aboutus } from "../../../assets/images/svg/aboutus.svg";
// import { ReactComponent as Question } from "../../../assets/images/svg/question.svg";
import CarouselComponent from "../CarouselComponent";
import CategoryCard from "./CategoryCard";
// import FlowTimeLine from "./FlowTimeLine";
import { data, techData } from "./helper";
import NewsLetter from "./NewsLetter";
import TechnologyCard from "./TechnologyCard";
import Head from "next/head";
// import CommunityAnnouncement from "./CommunityAnnouncement";
import { ProgramUpdates } from "./SharedComponents";
// import ExploreOurLearningProcess from "./ExploreOurLearningProcess";
import ProgramThatMakeDiff from "./ProgramsThatMakeDiff";
// import { FooterSection } from "../Community/components/Community/SharedComponents";
// import { LifeHere } from "../Community/components/Carrer/LifeHere.jsx";
import MomentsOfFun from "./MomentsOfFun.jsx";
import SuccessStories from "./SuccessStories.jsx";
import GrowLearningCommunity from "./GrowLearningCommunity.jsx";

class LandingPage extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.showFooter();
  }

  componentWillUnmount() {
    this.props.hideFooter();
  }

  render() {
    const description =
      "We at Datacode.in aims in creating a platform for beginners' communities where they will get the best online technical content like the latest technical blogs, tutorials and courses, practice questions, quizzes, training and certification, and lot more on a single platform.";
    const title = "Computer Science Learning Community | Datacode.in";
    return (
      <>
        <Head>
          <meta
            property="og:title"
            content="Computer Science Community"
            key="title"
          />
        </Head>
        <Helmet>
          <title>{title}</title>
          <meta
            data-react-helmet="true"
            name="description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            http-equiv="cleartype"
            content="on"
          ></meta>
          <meta
            data-react-helmet="true"
            name="apple-mobile-web-app-capable"
            content="yes"
          ></meta>
          <meta
            data-react-helmet="true"
            name="robots"
            content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:locale"
            content="en_US"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:type"
            content="website"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:title"
            content={title}
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:url"
            content="https://www.datacode.in/"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:site_name"
            content="Datacode.in"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:secure_url"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:width"
            content="400"
          ></meta>
          <meta
            data-react-helmet="true"
            property="og:image:height"
            content="400"
          ></meta>
          <meta
            data-react-helmet="true"
            name="keywords"
            content="programming,coding,online, code online, online judge, practice, run code, test, expected outcome, programming competition, programming contest, online programming, online computer programming"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:card"
            content="summary_large_image"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:description"
            content={description}
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:title"
            content={title}
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:site"
            content="@datacode_in"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:creator"
            content="@datacode_in"
          ></meta>
          <meta
            data-react-helmet="true"
            name="twitter:image"
            content="https://res.cloudinary.com/datacode/image/upload/v1617290788/f5gjqvd1wd8o2u9hfdrb.png"
          ></meta>
          <link
            data-react-helmet="true"
            rel="shortlink"
            href="https://www.datacodoe.in/"
          ></link>
          <link
            data-react-helmet="true"
            rel="dns-prefetch"
            href="//www.datacode.in"
          ></link>
          <link
            data-react-helmet="true"
            rel="dns-prefetch"
            href="//s.w.org"
          ></link>
          <link
            data-react-helmet="true"
            rel="alternate"
            type="application/rss+xml"
            title="Datacode"
            href="https://www.datacode.in/"
          ></link>
          <link
            data-react-helmet="true"
            rel="canonical"
            href="https://www.datacode.in/"
          ></link>
        </Helmet>
        <div className="landing-carousel">
          <CarouselComponent
            name={this.props.currentUser && this.props.currentUser.firstName}
          />
        </div>
        <div className="row m-0 justify-content-center community-announcement my-5 pt-md-5 px-md-4 px-0">
          <div className="col-12 col-md-10  mb-5">
            {/* <EventNews /> */}
            <ProgramUpdates />
          </div>
          {/* <div className="col-12 col-md-10 px-md-5 px-3">
            <CommunityAnnouncement />
          </div> */}
          {/* <div className="col-12 col-md-6 text-center d-flex justify-content-center align-items-center mb-5 mb-md-0 pt-3">
                <Volunteer width="400px" height="400px" />
              </div> */}
        </div>

        {/* <div className="py-lg-5">
          <ExploreOurLearningProcess />
        </div> */}
        <div className="py-lg-5">
          <ProgramThatMakeDiff />
        </div>
        <div className="px-md-5 px-0">
          {/* <div className="row m-0 p-md-5 community-section main">
            
            <div className='col-12 col-md-6 text-center left'>
              <Question width='100%' height='Auto' />
            </div>
            <div className='col-12 col-md-6 pt-5 pr-4 text-center right'>
              <h3>Are you a Computer Science Beginner?</h3>
              <h5 className='h5 mt-1'>And not sure where to start from?</h5>
              <p className='mt-3 pr-4  mb-0'>
                We at Datacode.in aims in creating a platform for beginners'
                communities where they will get the best online technical
                content like the latest technical blogs, tutorials and courses,
                practice questions, quizzes, training and certification, and lot
                more on a single platform.
              </p>
            </div> 
          </div> */}
          {/* <div className="row my-5 p-md-5 p-3">
            <div className="col-12 text-center">
              <h6 className="h5 mb-0 p-md-2 mx-md-3 px-2">
                Learning has never been this easy, then what are you waiting
                for?
              </h6>
              <h2 className="mt-2 mt-md-2">
                Join Datacode.in and #Learn with Community
              </h2>
              {this.props.currentUser && this.props.currentUser.userName ? (
                <Link to="/tutorials">
                  <button className="btn join-us-button shadow-lg">
                    <i className="fa fa-search search-icon" /> Find Courses
                  </button>
                </Link>
              ) : (
                <Link to="/signup">
                  <button className="btn join-us-button text-center card-schadow">
                    Join Community
                  </button>
                </Link>
              )}
            </div>
          </div>
          <div className="row m-0 p-md-5 aboutus-section">
            <div className="col-12 col-md-6 pt-5 text-center">
              <h3>What we do!</h3>
              <p className="mt-3 pr-4 text-center">
                Datacode Provides you the Best free Learning Content from all
                over the world to Improve your Skills and Make your Learning
                Easy and Quick.
                <br />
                Either one is at Beginners or at the Advance level you find your
                track and learn from it.
              </p>
              <Link to="/our_story">
                <div className="find-out-more shadow-lg p-3 text-center mx-auto">
                  <p className="text-white">
                    We aim at creating a better learning experience by dividing
                    Datacode.in in the various categories to make it easier for
                    you
                  </p>
                  <span className="find-out-more">
                    Find out more <i className="fal fa-long-arrow-right" />
                  </span>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-6 text-center">
              <Aboutus width="100%" height="Auto" />
            </div>
          </div> */}
        </div>
        {/* <FlowTimeLine /> */}
        <GrowLearningCommunity />
        <div className="row m-0 my-md-5 my-3 category-section text-center">
          <div className="col-12 text-center">
            <h2 className="text-center py-md-5">
              Explore Our Learning Resources
            </h2>
          </div>
          <div className="row mt-md-3 mt-5 px-md-5 px-3 mx-md-0 mx-3 text-center d-flex justify-content-center">
            {data.map((item, i) => (
              <CategoryCard
                key={i}
                categoryName={item.categoryName}
                svgSource={item.svgSource}
                categoryDiscription={item.categoryDiscription}
                link={item.link}
              />
            ))}
          </div>
        </div>

        <div className="row m-0 category-section text-center justify-content-center">
          <div className="col-12 text-center">
            <h2 className="text-center">
              What technology do you want to learn today?
            </h2>
          </div>
          <div className="row mt-5 px-md-4  mx-md-0 mx-3 text-left d-flex justify-content-center">
            {techData.map((item, i) => (
              <TechnologyCard
                key={i}
                technologyName={item.technologyName}
                svgSource={item.svgSource}
                technologyDiscription={item.technologyDiscription}
                link={item.link}
              />
            ))}
          </div>
          <div className="col-12 text-right px-5 my-3">
            <Link to="/tutorials">
              <h6 className="text-right justify-content-right">
                Find out more <i className="fal fa-long-arrow-right" />
              </h6>
            </Link>
          </div>
        </div>
        {/* <LifeHere /> */}
        <div className="py-lg-5">
          <SuccessStories />
        </div>
        <div className="py-lg-5">
          <MomentsOfFun />
        </div>
        <NewsLetter />
      </>
    );
  }
}

LandingPage.defaultProps = {
  name: "",
  currentUser: {},
  firstName: "",
};

LandingPage.propTypes = {
  name: PropTypes.string,
  currentUser: PropTypes.instanceOf(Object),
  firstName: PropTypes.string,
};

export default LandingPage;
