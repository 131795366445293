import React, { useState } from "react";
import { growLearningData, LearningGrowCarousel } from "./LearningGrowCarousal";

const GrowLearningCommunity = () => {
  const [selectedIndex, setIndex] = useState(0);

  const handleChange = (index) => {
    setIndex(index);
    console.log(index);
  };

  return (
    <>
      <div className="row mx-0 grow-learning-community">
        <div className="col-12 px-0 ">
          <h1 className="text-center">
            How will you grow with learning community?
          </h1>
          <div className="row mx-0 justify-content-center py-5">
            <div className="col-lg-10 px-0 col-12 px-lg-5 justify-content-center align-items-center">
              <div className="navbar-nav d-flex flex-row justify-content-around align-items-center ">
                {growLearningData.map((data, index) => (
                  <div
                    className={`nav-item pointer-event p-0 d-flex flex-column gap-1 justify-content-center align-items-center ${selectedIndex === index ? "active " : ""
                      }`}
                    key={index}
                    onClick={() => handleChange(index)}
                    role="button"
                    tabIndex="0"
                    aria-label={`Navigate to ${data.title1}`}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") handleChange(index);
                    }}
                  >
                    <img

                      src={data.img}
                      alt={data.title1}
                      loading="lazy"
                      className="mx-auto img-fluid grow-community-img-section"
                    />
                    <h6 className="text-uppercase">{data.title1}</h6>
                  </div>
                ))}
              </div>
              <div className="border border-bottom"></div>
            </div>
          </div>
          <div className="row mx-0 justify-content-center px-lg-5 px-0">
            <div className="col-lg-10 px-0 py-5 border rounded">
              <LearningGrowCarousel selectedIndex={selectedIndex} setIndex={setIndex} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrowLearningCommunity;

